import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

const ThreeScene = ({ volumes, stlUrl }) => {
  const visualizationRef = useRef();

  useEffect(() => {
    const material = new THREE.MeshPhongMaterial({
      color: 0xdddddd,
      specular: 0x999999,
      shininess: 100,
    });

    const boxMaterial = new THREE.MeshPhongMaterial({
      color: 0x7df9ff,
      transparent: true,
      opacity: 0.5,
      specular: 0x050505,
      shininess: 10,
    });

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xd3d3d3);

    const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 10000);
    camera.position.z = 1000; // Initial camera position
    camera.lookAt(scene.position);

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(visualizationRef.current.clientWidth, visualizationRef.current.clientHeight);
    visualizationRef.current.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0x404040, 3); // Increased intensity
    scene.add(ambientLight);

    const directionalLight1 = new THREE.DirectionalLight(0xffffff, 1); // Increased intensity
    scene.add(directionalLight1);

    const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1); // Increased intensity
    scene.add(directionalLight2);

    const composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));

    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(visualizationRef.current.clientWidth, visualizationRef.current.clientHeight),
      1.5,
      0.4,
      0.85
    );
    composer.addPass(bloomPass);

    const adjustLighting = (mesh) => {
      const boundingBox = new THREE.Box3().setFromObject(mesh);
      const center = boundingBox.getCenter(new THREE.Vector3());
      const size = boundingBox.getSize(new THREE.Vector3());

      const maxDim = Math.max(size.x, size.y, size.z);

      // Adjust camera position
      camera.position.z = maxDim * 2;
      camera.lookAt(center);

      // Adjust light positions based on model size and center
      directionalLight1.position.set(center.x + maxDim, center.y + maxDim, center.z + maxDim);
      directionalLight2.position.set(center.x - maxDim, center.y - maxDim, center.z - maxDim);
    };

    if (stlUrl) {
      const loader = new STLLoader();
      fetch(stlUrl)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          const geometry = loader.parse(buffer);
          const mesh = new THREE.Mesh(geometry, material);
          scene.add(mesh);
          adjustLighting(mesh);
          onWindowResize();
        })
        .catch((error) => console.error("Error loading STL:", error));
    }

    volumes.forEach((volume) => {
      const { extents, origin } = volume;
      const boxGeometry = new THREE.BoxGeometry(...extents);
      const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
      boxMesh.position.set(
        origin[0] + extents[0] / 2,
        origin[1] + extents[1] / 2,
        origin[2] + extents[2] / 2
      );
      scene.add(boxMesh);

      const edges = new THREE.EdgesGeometry(boxGeometry);
      const line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({ color: 0xffffff })
      );
      line.position.set(
        origin[0] + extents[0] / 2,
        origin[1] + extents[1] / 2,
        origin[2] + extents[2] / 2
      );
      scene.add(line);
    });

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", () => renderer.render(scene, camera));

    const animate = () => {
      requestAnimationFrame(animate);
      composer.render();
    };
    animate();

    const onWindowResize = () => {
      const width = visualizationRef.current.clientWidth;
      const height = visualizationRef.current.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
      composer.setSize(width, height);
    };
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
      visualizationRef.current.removeChild(renderer.domElement);
      controls.dispose();
      scene.clear();
      renderer.dispose();
    };
  }, [volumes, stlUrl]);

  return (
    <div
      ref={visualizationRef}
      id="mesh-visualization"
      style={{ width: "100%", height: "400px" }}
      className="col p-0"
    ></div>
  );
};

export default ThreeScene;

import React, { useState, useEffect, useRef } from "react";
import Spinner from "react-bootstrap/Spinner";

const MeshSlicerForm = ({ onVolumesChanged, onFileChange }) => {
  const [meshFile, setMeshFile] = useState(null);
  const [dimensions, setDimensions] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDimensionsValid, setIsDimensionsValid] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const visualizationRef = useRef();

  const dimensionRegex = /^\d{2,},\d{2,},\d{2,}$/;

  useEffect(() => {
    setIsDimensionsValid(dimensionRegex.test(dimensions));
  }, [dimensions]);

  useEffect(() => {
    setIsFileSelected(!!meshFile);
  }, [meshFile]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMeshFile(file);
      onFileChange(file); // Pass the selected file up to the parent component
    }
  };

  const handleVisualize = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", meshFile);
    formData.append("build_volume_dimensions", dimensions);

    setLoading(true);
    try {
      const response = await fetch(`https://yyy.printmeister.xyz/api/visualize`, {
        method: "POST",
        body: formData,
      });
      const volumes = await response.json();
      onVolumesChanged(volumes);
    } catch (error) {
      console.error(error);
      // Handle error
    }
    setLoading(false);
  };

  const handleSlice = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", meshFile);
    formData.append("build_volume_dimensions", dimensions);

    try {
      setLoading(true);
      const response = await fetch(`https://yyy.printmeister.xyz:1001/api/split`, {
        method: "POST",
        body: formData,
      });
      setLoading(false);
      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "sliced_mesh.zip"); // or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error("Server responded with an error.");
      }
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  return (
    <>
      {loading && (
        <>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </>
      )}
      <h2>Mesh Slicer</h2>
      <div className="card p-3">
        <div className="">
          <div ref={visualizationRef} id="mesh-visualization"></div>
          <form method="post" encType="multipart/form-data">
            <div className="row">
              <div className="col-md col-lg-6">
                <label htmlFor="mesh_file">Mesh File</label>
                <input
                  type="file"
                  className="form-control"
                  name="mesh_file"
                  id="mesh_file"
                  required
                  onChange={handleFileInputChange}
                />
              </div>
              <div className="col-md col-lg-6">
                <label htmlFor="dimensions">Dimensions: L,W,H</label>
                <input
                  type="text"
                  className="form-control"
                  name="dimensions"
                  id="dimensions"
                  placeholder="200,200,200 will be used if this is blank"
                  required
                  value={dimensions}
                  onChange={(e) => setDimensions(e.target.value)}
                />
                <span className="text-secondary"></span>
              </div>
              <div className="form-group mt-3">
                <button
                  onClick={handleVisualize}
                  type="button"
                  id="visualize-btn"
                  className="btn btn-primary"
                  disabled={!isDimensionsValid || !isFileSelected}
                >
                  Visualize
                </button>
                <button
                  onClick={handleSlice}
                  type="button"
                  id="slice-btn"
                  className="btn btn-success ms-3"
                  disabled={!isDimensionsValid || !isFileSelected}
                >
                  Confirm Slice
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default MeshSlicerForm;

import React, { useState } from "react";
import MeshSlicerForm from "./MeshSlicerForm";
import ThreeScene from "./ThreeScene";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [volumes, setVolumes] = useState([]);
  const [stlUrl, setStlFileUrl] = useState([]);

  const handleFileChange = (file) => {
    // Create a URL for the STL file
    const fileUrl = URL.createObjectURL(file);
    setStlFileUrl(fileUrl);
  };

  return (
    <div style={{height:"100vh"}} className="bg-light">
      <div className="container">
        <div className="row">
          <MeshSlicerForm
            onVolumesChanged={setVolumes}
            onFileChange={handleFileChange}
          />
        </div>
        <div className="row">
          <ThreeScene volumes={volumes} stlUrl={stlUrl} />
        </div>
      </div>
    </div>
  );
}

export default App;
